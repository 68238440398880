import '../scss/style.scss';
import Swiper from 'swiper';
import { Navigation, Pagination, Autoplay, Grid } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/grid';
import Sticky from 'sticky-js';
import MiniMasonry from 'minimasonry';

document.addEventListener('DOMContentLoaded', function () {

    const searchBtn = document.querySelector('.search-btn');
    const cancel = document.querySelector('.cancel');
    const cancel2 = document.querySelector('.close-cap-search');
    const modalSearch = document.querySelector('.modal-search');
    const searchInput = document.querySelector('#search-form input');

    searchBtn.addEventListener('click', () => {
        modalSearch.classList.remove('hidden');
        modalSearch.classList.add('fadeIn');
        searchInput.focus();
    });

    function closeSearchModal() {
        modalSearch.classList.add('fadeOut');
        setTimeout(() => {
            modalSearch.classList.add('hidden');
            modalSearch.classList.remove('fadeOut');
            searchInput.value = '';
        }, 500);
    }

    cancel.addEventListener('click', closeSearchModal);
    cancel2.addEventListener('click', closeSearchModal);

    const swiper3 = new Swiper('.slider-blog', {
        modules: [Navigation, Pagination, Autoplay, Grid],
        slidesPerView: 1,
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        breakpoints: {
            0: {
                autoHeight: true,
            },
            680: {
                autoHeight: false,
            },
        }
    });

    if (document.querySelector('#dataTable')) {
        const typeFilter = document.getElementById("type-filter");
        const dateFilter = document.getElementById("date-filter");
        const rows = document.querySelectorAll("#dataTable tbody tr");

        function filterRows() {
            const selectedType = typeFilter.value;
            const selectedDate = dateFilter.value;

            rows.forEach((row, index) => {
                const rowType = row.querySelector("[data-type]").getAttribute("data-type");
                const rowYear = row.querySelector("[data-year]").getAttribute("data-year");

                const typeMatch = selectedType === "" || selectedType === rowType;
                const dateMatch = selectedDate === "" || selectedDate === rowYear;

                if (selectedType === "" && selectedDate === "") {
                    row.classList.remove("hidden");
                    if (index >= 5) {
                        row.classList.add("hidden");
                    }
                } else if (typeMatch && dateMatch) {
                    row.classList.remove("hidden");
                } else {
                    row.classList.add("hidden");
                }
            });
        }

        typeFilter.addEventListener("change", filterRows);
        dateFilter.addEventListener("change", filterRows);
        filterRows();

        const showMoreButton = document.getElementById("show-more-table");
        const tableBody = document.querySelector("#dataTable tbody");
        const rows2 = tableBody.querySelectorAll("tr");
        const batchSize = 5;
        let visibleRowCount = 5;
        let allResultsShown = false;

        function updateTableDisplay() {
            rows2.forEach((row, index) => {
                if (index < visibleRowCount) {
                    row.classList.remove("hidden");
                } else {
                    row.classList.add("hidden");
                }
            });

            showMoreButton.style.pointerEvents = allResultsShown ? "none" : "auto";
        }

        function showMoreRows() {
            visibleRowCount += batchSize;

            if (visibleRowCount >= rows.length) {
                allResultsShown = true;
            }

            updateTableDisplay();
        }

        showMoreButton.addEventListener("click", showMoreRows);
        updateTableDisplay();
    }

    // if (document.querySelector('.generate-more-inputs')) {
    //     const generateButton = document.querySelector('.generate-more-inputs');
    //     const moreInfoContainer = document.querySelector('.more-info');
    //     let sectionCount = 1;

    //     generateButton.addEventListener('click', function (event) {
    //         event.preventDefault();
    //         createInputs();
    //     });

    //     moreInfoContainer.addEventListener('click', function (event) {
    //         const deleteButton = event.target.closest('.delete-this');
    //         if (deleteButton) {
    //             event.preventDefault();
    //             event.stopPropagation();
    //             deleteSection(deleteButton.closest('.parent-append'));
    //         }
    //     });

    //     function applyScriptToFields(fields) {
    //         fields.forEach((field) => {
    //             toggleFilledClass(field);

    //             field.addEventListener("input", () => {
    //                 toggleFilledClass(field);
    //             });

    //             field.addEventListener("blur", () => {
    //                 toggleFilledClass(field);
    //             });

    //             field.addEventListener("focus", () => {
    //                 const label = field.closest("label");
    //                 label.classList.add("filled");
    //             });
    //         });
    //     }

    //     function createInputs() {
    //         sectionCount++;
    //         const parentAppend = document.createElement("div");
    //         parentAppend.classList.add("parent-append", "col-span-12");
    //         parentAppend.innerHTML = `
    //         <div class="grid grid-cols-12 gap-x-[3.6rem] sm:gap-x-0">
    //             <div class="col-span-12">
    //                 <h2 class="mt-[4rem] text-[2.2rem] font-bold mb-[3.8rem] text-green86 sm:mt-[1rem] sm:mb-[1.8rem]">
    //                     4. Información laboral <span class="number-count">${sectionCount}</span>
    //                 </h2>
    //             </div>
    //             <div class="col-span-12 text-left inputs-animation mb-[1.5rem] sm:col-span-12 sm:gap-x-0">
    //                 <label class="block relative">
    //                     <span class="bt text-silver6c text-[1.6rem] font-bold
    //                     absolute top-[50%] translate-y-[-50%] h-auto sm:text-[1.4rem]"><div>Nombres de la empresa</div></span>
    //                     <input name="name_emp" type="text" class="
    //                         mt-0
    //                         mb-[.8rem]
    //                         text-[1.6rem]
    //                         block
    //                         w-full
    //                         px-0
    //                         font-[500]
    //                         bg-transparent
    //                         h-[4.6rem]
    //                         text-silverc6
    //                         border-0
    //                         border-b-[.1rem] border-[#c4c4c4]
    //                         focus:outline-none
    //                         focus:ring-0
    //                         focus:border-[#c4c4c4]
    //                     " required>
    //                 </label>
    //             </div>
    //             <div class="col-span-6 text-left inputs-animation mb-[1.5rem] sm:col-span-12 sm:gap-x-0">
    //                 <label class="block relative">
    //                     <span class="bt text-silver6c text-[1.6rem] font-bold
    //                     absolute top-[50%] translate-y-[-50%] h-auto sm:text-[1.4rem]"><div>Cargo</div></span>
    //                     <input name="cargo" type="text" class="
    //                         mt-0
    //                         mb-[.8rem]
    //                         text-[1.6rem]
    //                         block
    //                         w-full
    //                         px-0
    //                         font-[500]
    //                         bg-transparent
    //                         h-[4.6rem]
    //                         text-silverc6
    //                         border-0
    //                         border-b-[.1rem] border-[#c4c4c4]
    //                         focus:outline-none
    //                         focus:ring-0
    //                         focus:border-[#c4c4c4]
    //                     " required>
    //                 </label>
    //             </div>
    //             <div class="col-span-6 text-left inputs-animation mb-[1.5rem] sm:col-span-12 sm:gap-x-0">
    //                 <label class="block relative">
    //                     <span class="bt text-silver6c text-[1.6rem] font-bold
    //                     absolute top-[50%] translate-y-[-50%] h-auto sm:text-[1.4rem]"><div>Funciones</div></span>
    //                     <input name="funciones" type="text" class="
    //                         mt-0
    //                         mb-[.8rem]
    //                         text-[1.6rem]
    //                         block
    //                         w-full
    //                         px-0
    //                         font-[500]
    //                         bg-transparent
    //                         h-[4.6rem]
    //                         text-silverc6
    //                         border-0
    //                         border-b-[.1rem] border-[#c4c4c4]
    //                         focus:outline-none
    //                         focus:ring-0
    //                         focus:border-[#c4c4c4]
    //                     " required>
    //                 </label>
    //             </div>
    //             <div class="col-span-12 block relative mb-[1.2rem] sm:pt-[2rem]">
    //                 <label class="block relative">
    //                     <span class="text-silver6c text-[1.6rem] font-bold mb-[1.6rem] block"><div>Funciones</div></span>
    //                     <textarea name="textarea3" class="
    //                         mt-0
    //                         mb-[.8rem]
    //                         block
    //                         w-full
    //                         py-[1.5rem]
    //                         px-[2.1rem]
    //                         text-[1.6rem]
    //                         text-silverc6
    //                         font-[500]
    //                         bg-[#f6fcff]
    //                         h-[13.9rem]
    //                         text-black09
    //                         rounded-[.9rem]
    //                         resize-none
    //                         border-[.1rem] border-[#c4c4c4]
    //                         focus:ring-0 focus:border-[#c4c4c4]
    //                         placeholder:text-[1.6rem]
    //                         placeholder:text-silver5b
    //                     " placeholder="Escribe tu mensaje" required></textarea>
    //                 </label>
    //             </div>
    //             <div class="col-span-6 text-left inputs-animation mb-[1.5rem] sm:col-span-12 sm:gap-x-0">
    //                 <label class="block relative">
    //                     <span class="bt text-silver6c text-[1.6rem] font-bold
    //                     absolute top-[50%] translate-y-[-50%] h-auto sm:text-[1.4rem]"><div>Tiempo Laborado</div></span>
    //                     <input name="tiempo" type="text" class="
    //                         mt-0
    //                         mb-[.8rem]
    //                         text-[1.6rem]
    //                         block
    //                         w-full
    //                         px-0
    //                         font-[500]
    //                         bg-transparent
    //                         h-[4.6rem]
    //                         text-silverc6
    //                         border-0
    //                         border-b-[.1rem] border-[#c4c4c4]
    //                         focus:outline-none
    //                         focus:ring-0
    //                         focus:border-[#c4c4c4]
    //                     " required>
    //                 </label>
    //             </div>
    //             <div class="col-span-12 block relative mb-[1.2rem] sm:pt-[2rem]">
    //                 <label class="block relative">
    //                     <span class="text-silver6c text-[1.6rem] font-bold mb-[1.6rem] block"><div>Motivo de retiro</div></span>
    //                     <textarea name="textarea4" class="
    //                         mt-0
    //                         mb-[.8rem]
    //                         block
    //                         w-full
    //                         py-[1.5rem]
    //                         px-[2.1rem]
    //                         text-[1.6rem]
    //                         text-silverc6
    //                         font-[500]
    //                         bg-[#f6fcff]
    //                         h-[13.9rem]
    //                         text-black09
    //                         rounded-[.9rem]
    //                         resize-none
    //                         border-[.1rem] border-[#c4c4c4]
    //                         focus:ring-0 focus:border-[#c4c4c4]
    //                         placeholder:text-[1.6rem]
    //                         placeholder:text-silver5b
    //                     " placeholder="Escribe tu mensaje" required></textarea>
    //                 </label>
    //             </div>
    //             <div class="col-span-12">
    //                 <a href="#" class="delete-this text-center text-[1.6rem] text-[#fc0000] underline mb-[2rem] block"> eliminar </a>
    //             </div>
    //         </div>
    //         `;
    //         moreInfoContainer.appendChild(parentAppend);
    //         const formFields = parentAppend.querySelectorAll("input, textarea, select");
    //         applyScriptToFields(formFields);
    //     }
    //     document.querySelector('.generate-more-inputs').addEventListener('click', function (event) {
    //         event.preventDefault();
    //         const formFields = moreInfoContainer.querySelectorAll("input, textarea, select");
    //         applyScriptToFields(formFields);
    //     });

    //     function deleteSection(section) {
    //         sectionCount--;
    //         section.remove();
    //         updateNumberCount();
    //     }

    //     function updateNumberCount() {
    //         const numberCounts = document.querySelectorAll('.number-count');
    //         numberCounts.forEach((count, index) => {
    //             count.textContent = sectionCount + index;
    //         });
    //     }
    // }

    if(document.querySelector('.generate-more-inputs')) {
        const moreInputs = document.querySelector('.generate-more-inputs');
        const deleteThis = document.querySelector('.delete-this');
        let allHiddenInputs = document.querySelectorAll('.more-form-fields');

        moreInputs.addEventListener('click', (e) => {
            e.preventDefault();
            e.target.closest('.gfield--type-html').style.display = 'none';
            allHiddenInputs.forEach(input => {
                input.style.display = 'block';
            });
        })

        deleteThis.addEventListener('click', (e) => {
            e.preventDefault();
            allHiddenInputs.forEach(input => {
                input.style.display = 'none';
            });
            moreInputs.closest('.gfield--type-html').style.display = 'block';
            allHiddenInputs.forEach(container => {
                container.querySelectorAll('input, textarea').forEach(input => {
                    input.value = '';
                });
            });
        });
    }

    if (document.querySelector('#home-order-select')) {
        document.getElementById('home-order-select').addEventListener('change', function () {
            const selectedIndex = this.selectedIndex;
            const formWrappers = document.querySelectorAll('.form-wrapper');

            formWrappers.forEach((formWrapper, index) => {
                if (index === selectedIndex) {
                    formWrapper.classList.remove('hidden');
                    formWrapper.classList.add('block');
                } else {
                    formWrapper.classList.remove('block');
                    formWrapper.classList.add('hidden');
                }
            });
        });
    }

    if (window.innerWidth > 960) {
        let sticky = new Sticky('.col-fixed');
    }

    const swiper = new Swiper('.slider-home', {
        modules: [Navigation, Pagination, Autoplay],
        loop: true,
        slidesPerView: 1,
        spaceBetween: 58,
        autoplay: {
            delay: 15000,
        },
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
    });

    var minislider = document.querySelectorAll('.mini-slider');
    minislider.forEach(function (sliderContainer) {
        const mini = new Swiper(sliderContainer, {
            modules: [Navigation, Pagination, Autoplay],
            loop: true,
            slidesPerView: 1,
            spaceBetween: 58,
            navigation: {
                nextEl: sliderContainer.parentNode.querySelector('.swiper-mini-next2'),
                prevEl: sliderContainer.parentNode.querySelector('.swiper-mini-prev2'),
            },
            autoplay: {
                delay: 5000,
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
        });
    });

    const swiper2 = new Swiper('.gallery-mini', {
        modules: [Navigation, Pagination, Autoplay],
        loop: true,
        slidesPerView: 1,
        spaceBetween: 58,
        autoplay: {
            delay: 5000,
        },
        pagination: {
            el: '.swiper-pagination2',
            clickable: true,
        },
    });

    if (document.querySelector('.strange-gallery')) {
        function isPartialElementInViewport(el) {
            var rect = el.getBoundingClientRect();
            return (
                rect.bottom > 0 &&
                rect.right > 0 &&
                rect.top < (window.innerHeight || document.documentElement.clientHeight) &&
                rect.left < (window.innerWidth || document.documentElement.clientWidth)
            );
        }

        function updateEnterScrollClass() {
            var strangeGallery = document.querySelector('.strange-gallery');

            if (isPartialElementInViewport(strangeGallery)) {
                setTimeout(function () {
                    strangeGallery.classList.add('enter-scroll');
                }, 1000);
            } else {
                strangeGallery.classList.remove('enter-scroll');
            }
        }

        window.addEventListener('scroll', function () {
            updateEnterScrollClass();
        });

        window.addEventListener('load', function () {
            updateEnterScrollClass();
        });

        // const galleryImageWrapper = document.querySelectorAll('.gallery-content-grid .image-wrapper');

        // galleryImageWrapper.forEach(image => {
        //     const randomHeight = Math.floor(Math.random() * (40 - 20 + 1)) + 20;
        //     image.style.height = `${randomHeight}rem`;
        // });

        setTimeout(() => {
            let masonry = new MiniMasonry({
                container: '.gallery-content-grid',
                gutter: 20,
                ultimateGutter: 20,
                surroundingGutter: false
            });
        }, 500);
    }

    const parentSubs = document.querySelectorAll(".parent-sub");

    parentSubs.forEach(function (parentSub) {
        parentSub.addEventListener("click", function () {
            const contentSub = parentSub.querySelector(".content-sub");
            const topSub = parentSub.querySelector(".top-sub");

            if (contentSub.classList.contains("hidden")) {
                closeAll();
                contentSub.classList.remove("hidden");
                contentSub.classList.add("block");
                slideDown(contentSub);
                this.classList.add("rotate-item");
                topSub.classList.add("active");


            } else {
                contentSub.classList.remove("block");
                contentSub.classList.add("hidden");
                slideUp(contentSub);
                this.classList.remove("rotate-item");
                topSub.classList.remove("active");
            }
        });
    });

    function closeAll() {
        const allContentSubs = document.querySelectorAll(".content-sub");
        const allOpenSubs = document.querySelectorAll(".open-sub");
        const allTopSubs = document.querySelectorAll(".top-sub");

        allContentSubs.forEach(function (contentSub) {
            if (!contentSub.classList.contains("hidden")) {
                contentSub.classList.remove("block");
                contentSub.classList.add("hidden");
                slideUp(contentSub);
            }
        });

        allOpenSubs.forEach(function (openSub) {
            openSub.classList.remove("rotate-item");
        });

        allTopSubs.forEach(function (topSub) {
            topSub.classList.remove("active");
        });
    }

    function slideUp(element) {
        element.style.maxHeight = "0";
        element.style.overflow = "hidden";
        element.style.transition = "max-height 0.3s ease-out";
    }

    function slideDown(element) {
        element.style.maxHeight = element.scrollHeight + "px";
        element.style.overflow = "hidden";
        element.style.transition = "max-height 0.5s ease-in";
    }

    const subNavOpens = document.querySelectorAll(".sub-nav-open");
    const mainNavigationParent = document.querySelector(".main-navigation-parent");
    const closeNavigation = document.querySelector(".close-navigation");
    const closeCap = document.querySelector(".close-cap");

    subNavOpens.forEach(function (subNavOpen) {
        subNavOpen.addEventListener("click", function (e) {
            e.preventDefault();
            mainNavigationParent.classList.remove("hidden");
            mainNavigationParent.classList.add("flex");
    
            const dataItem = this.dataset.item;
            const parentSub = document.querySelector(`.parent-sub.${dataItem}`);

            if (parentSub) {
                parentSub.dispatchEvent(new Event('click'));
            }
    
            setTimeout(function () {
                document.querySelector(".navigation-show").classList.add("active");
            }, 300);
        });
    });

    const closeNavigationFunction = function () {
        document.querySelector(".navigation-show").classList.remove("active");
        setTimeout(function () {
            mainNavigationParent.classList.remove("flex");
            mainNavigationParent.classList.add("hidden");
        }, 300);
    };

    closeNavigation.addEventListener("click", closeNavigationFunction);
    closeCap.addEventListener("click", closeNavigationFunction);

    const tabClicks = document.querySelectorAll(".tab-click");
    const galleries = document.querySelectorAll(".gallery");
    const textWrappers = document.querySelectorAll(".content-text .text-wrapper");

    tabClicks.forEach(function (tabClick, index) {
        tabClick.addEventListener("click", function () {
            galleries.forEach(function (gallery) {
                gallery.classList.add("!hidden");
            });
            textWrappers.forEach(function (textWrapper) {
                textWrapper.classList.add("hidden");
            });

            galleries[index].classList.remove("!hidden");
            textWrappers[index].classList.remove("hidden");
        });
    });

    // Form Inputs
    function toggleFilledClass(field) {
        const label = field.closest(".gfield");

        if (!label) return;

        if (field.tagName === 'SELECT') {
            label.classList.toggle("filled", field.selectedIndex !== 0);
        } else {
            label.classList.toggle("filled", field.value.trim() !== '');
        }
    }
    const forms = document.querySelectorAll('.contact-form');
    forms.forEach((form) => {
        const formFields = form.querySelectorAll("input, textarea, select");

        formFields.forEach((field) => {
            toggleFilledClass(field);

            field.addEventListener("input", () => {
                toggleFilledClass(field);
            });

            field.addEventListener("blur", () => {
                toggleFilledClass(field);
            });

            field.addEventListener("focus", () => {
                const parent = field.closest(".gfield");
                if (parent) parent.classList.add("filled");
            });
        });
    });

    // Login Form Inputs
    if(document.querySelector('.login-form')) {
        function toggleFilledClass(field) {
            const label = field.closest("label");

            if (!label) return;

            if (field.tagName === 'SELECT') {
                label.classList.toggle("filled", field.selectedIndex !== 0);
            } else {
                label.classList.toggle("filled", field.value.trim() !== '');
            }
        }

        const loginForm = document.querySelector('.login-form');
        const formFields = loginForm.querySelectorAll("input");

        formFields.forEach((field) => {
            toggleFilledClass(field);

            field.addEventListener("input", () => {
                toggleFilledClass(field);
            });

            field.addEventListener("blur", () => {
                toggleFilledClass(field);
            });

            field.addEventListener("focus", () => {
                const label = field.closest("label");
                label.classList.add("filled");
            });
        });
    }

    if (document.querySelector('.contact-form')) {
        let fileUpload = document.querySelectorAll('.custom-input-file');

        fileUpload.forEach((file) => {
            const fileName = document.createElement('div');
            fileName.classList.add('file-preview', 'hidden');

            let elementAfter = file.querySelector('.gfield_label');

            if (elementAfter) {
                elementAfter.insertAdjacentElement('afterend', fileName);
            } else {
                file.appendChild(fileName);
            }
        });

        fileUpload.forEach(function (fileContainer) {
            const fileInput = fileContainer.querySelector('input[type=file]');
            const filePreview = fileContainer.querySelector('.file-preview');

            fileInput.addEventListener('change', function () {
                filePreview.innerHTML = '';

                if (fileInput.files.length > 0) {
                    filePreview.classList.add('flex', '!h-[4.6rem]');
                    filePreview.classList.remove('hidden');

                    Array.from(fileInput.files).forEach(function (file) {
                        const fileName = file.name;

                        const fileNameElement = document.createElement('span');
                        fileNameElement.textContent = fileName;

                        const deleteButton = document.createElement('button');
                        deleteButton.innerHTML = 'X';

                        deleteButton.addEventListener('click', function () {
                            filePreview.removeChild(fileItemContainer);
                            if (filePreview.children.length === 0) {
                                filePreview.classList.remove('flex', '!h-[4.6rem]');
                                filePreview.classList.add('hidden');
                            }
                        });

                        const fileItemContainer = document.createElement('div');
                        // fileItemContainer.classList.add('rounded-[2rem]', 'bg-[#f4f4f4]', 'px-[1.6rem]', 'flex', 'items-center', 'gap-x-[1rem]', 'py-[1rem]', 'w-full');
                        fileItemContainer.appendChild(fileNameElement);
                        fileItemContainer.appendChild(deleteButton);

                        filePreview.appendChild(fileItemContainer);
                    });
                } else {
                    filePreview.classList.remove('flex');
                    filePreview.classList.add('hidden');
                }
            });
        });
    }

    var titles = document.querySelectorAll('.accordion-title');
    var contents = document.querySelectorAll('.accordion-content');
    var icons = document.querySelectorAll('.accordion-icon');

    titles.forEach(function (title, index) {
        title.addEventListener('click', function () {
            var content = contents[index];
            var icon = icons[index];

            if (content.classList.contains('active')) {
                content.classList.remove('active');
                icon.innerHTML = '<i class="lni lni-plus"></i>';
                title.classList.remove('active');
            } else {
                closeAllAccordions();

                content.classList.add('active');
                icon.innerHTML = '<i class="lni lni-close"></i>';
                title.classList.add('active');
            }
        });
    });

    function closeAllAccordions() {
        contents.forEach(function (content) {
            content.classList.remove('active');
        });

        icons.forEach(function (icon) {
            icon.innerHTML = '<i class="lni lni-plus"></i>';
        });

        titles.forEach(function (title) {
            title.classList.remove('active');
        });
    }

    if (document.querySelector('.square-section')) {
        let cards = document.querySelectorAll('.square-section .square-section-card');

        cards.forEach(card => {
            let iconBg = card.querySelector('.image svg #home_cg');
            iconBg.style.fill = card.dataset.bg;

            card.addEventListener('mouseenter', () => {
                const bgColor = card.dataset.bg;
                const textHoverColor = card.dataset.text;
                card.style.background = bgColor;
                card.style.color = textHoverColor;
            });
            card.addEventListener('mouseleave', () => {
                card.style.background = '';
                card.style.color = '';
            });
        });
    }

    if (document.querySelector('.tax-wrapper')) {
        const urlParams = new URLSearchParams(window.location.search);
        const anioParam = urlParams.get('anio');

        if (anioParam !== null) {
            const anioSelect = document.getElementById('date-filter');
            anioSelect.value = anioParam;
        }

        const newsletterType = document.getElementById('type-filter');
        const currentSlug = window.location.pathname.split('/').filter(Boolean).pop(); // Obtener el slug de la página actual

        Array.from(newsletterType.options).forEach(option => {
            if (option.value.includes(currentSlug)) {
                newsletterType.value = option.value;
            }
        });

        newsletterType.addEventListener('change', (e) => {
            e.preventDefault();

            let url = e.target.value;

            if(url) {
                window.location.href = url;
            }
        });

        const newsletterDate = document.getElementById('date-filter');
        const fyear = urlParams.get('fyear');

        Array.from(newsletterDate.options).forEach(option => {
            if (option.value.includes(fyear)) {
                newsletterDate.value = option.value;
            }
        });

        newsletterDate.addEventListener('change', (e) => {
            e.preventDefault();

            let parameter = `fyear=${e.target.value}`;
            if (parameter == null) return;

            let url = window.location.href;
            url = url.replace(/\/page\/\d+\//, '/');

            if (url.includes('fyear=')) {
                url = url.replace(/fyear=[^&]+/, parameter);
            } else {
                url += (url.includes('?') ? '&' : '?') + parameter;
            }

            if (newsletterDate.value === '') {
                window.location.href = window.location.origin + window.location.pathname;
            } else {
                window.location.href = url;
            }
        });
    }

    const searchForm = document.getElementById("search-form");
    const formSearchInput = searchForm.querySelector("input[type='text']");
    const searchResults = document.getElementById("search-results");

    if (!searchForm || !formSearchInput || !searchResults) {
        return;
    }

    const ajaxUrl = searchForm.dataset.ajaxUrl;
    let debounceTimer;

    formSearchInput.addEventListener("input", function () {
        clearTimeout(debounceTimer);

        const query = formSearchInput.value;

        searchResults.style.display = "none";

        if (query.length > 2) {
            debounceTimer = setTimeout(() => {
                fetch(ajaxUrl + "?action=search_ajax&query=" + encodeURIComponent(query))
                    .then(response => {
                        if (response.ok) {
                            return response.text();
                        } else {
                            throw new Error('Error en la petición AJAX');
                        }
                    })
                    .then(text => {
                        searchResults.innerHTML = text;
                        searchResults.style.display = "block";
                    })
                    .catch(error => {
                        console.error('Error:', error);
                    });
            }, 300); // 300 ms de espera
        } else {
            searchResults.innerHTML = "";
        }
    });

    if(document.querySelector('.gallery-tab')) {
        const tabContainer = document.querySelector('.gallery-tab-container');
        const tabClick = document.querySelectorAll('.tab-click');

        tabClick.forEach(tab => {
            tab.addEventListener('click', () => {
                tabContainer.scrollLeft = 0;

                let rect = tab.getBoundingClientRect();
                let scrollOffset = rect.left - tabContainer.getBoundingClientRect().left - parseFloat(window.getComputedStyle(tabContainer).paddingLeft) - parseFloat(window.getComputedStyle(tab).marginLeft);
                tabContainer.scrollLeft += scrollOffset;
            });
        });
    }

    // Single Post Copy To Clipboard
    if(document.querySelector('.copy-to-clipboard')) {
        const copy = document.querySelector('.copy-to-clipboard');

        copy.addEventListener('click', (e) => {
            e.preventDefault();

            const currentUrl = window.location.href;
            navigator.clipboard.writeText(currentUrl)
                .then(() => {
                    const text = document.querySelector('.copied-success-text');
                    text.classList.add('active');

                    setTimeout(() => {
                        text.classList.remove('active');
                    }, 3000);
                })
                .catch((error) => {
                    console.error('Error al copiar URL al portapapeles: ', error);
                });
        });
    }

    // Login (.login-form)
    let loginForm = document.querySelector('.login-form');
    if(loginForm) {
        let sendingloginForm = false;
        const errorMessage = document.querySelector('.login-form-error-message');

        loginForm.addEventListener('submit', function(e) {
            e.preventDefault();
            errorMessage.classList.add('hidden');

            if (sendingloginForm) return;
            sendingloginForm = true;

            let data = new FormData(loginForm);
            fetch(loginForm.getAttribute('action'), {
                method: loginForm.getAttribute('method'),
                body: data
            })
            .then(response => response.json())
            .then(data => {
                sendingloginForm = false;

                if (data.success) {
                    loginForm.reset();
                    window.location.href = data.redirect_url;
                } else if (errorMessage) {
                    errorMessage.querySelector('span').textContent = data.message;
                    errorMessage.classList.remove('hidden');
                }
            })
            .catch((error) => {
                sendingloginForm = false;
                console.error('Error:', error);
            });
        });
    }

    // Folder List
    if(document.querySelector('.folder-list')) {
        const buttons = document.querySelectorAll('.folder-button');
        const searchInput = document.getElementById('searchInput');

        buttons.forEach(button => {
            button.addEventListener('click', function () {
                const folderContent = this.nextElementSibling;
                folderContent.classList.toggle('hidden');
                const icon = this.querySelector('.fa-chevron-down');
                icon.classList.toggle('active');
            });
        });

        searchInput.addEventListener('input', function() {
            const searchValue = this.value.toLowerCase();
            const fileItems = document.querySelectorAll('.file-item');
            const folderButtons = document.querySelectorAll('.folder-button');

            fileItems.forEach(item => {
                const fileName = item.getAttribute('data-file-name').toLowerCase();
                if (fileName.includes(searchValue)) {
                    item.classList.remove('hidden');
                } else {
                    item.classList.add('hidden');
                }
            });

            folderButtons.forEach(button => {
                const folderContent = button.nextElementSibling;
                const visibleItems = folderContent.querySelectorAll('.file-item:not(.hidden)');
                if (visibleItems.length === 0) {
                    button.classList.add('hidden');
                } else {
                    button.classList.remove('hidden');
                }
            });
        });
    }
});